@import './vars';

/* width */
.coolscroll::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
.coolscroll::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.8);
}

/* Handle */
.coolscroll::-webkit-scrollbar-thumb {
    background: linear-gradient(0deg, $redape 0%, $cyellow 100%);
    border-radius: 10px;
}

/* Handle on hover */
.coolscroll::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(0deg, $primary 0%, $hgold 100%);
}
