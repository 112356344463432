@import 'vars';
@import './font-helpers.scss';
@import './color-helpers.scss';
@import '~bulma';
@import './scroll-helpers.scss';
@import './custom-notifications.scss';

html,
body,
#root {
    width: 100%;
    height: 100%;

    @extend .coolscroll;
    scroll-behavior: smooth;
    // overflow: hidden;
}

#root {
    padding-top: 52px;
}
