$notification-bg: #080808;

.notification__item__success {
    background: $notification-bg !important;
    border-left: 10px solid #00e4a2 !important;
    color: #efd0ff !important;
}

.notification__item__error {
    background: $notification-bg !important;
    border-left: 10px solid #eb004e !important;
    color: #efd0ff !important;
}

.notification__item__info {
    background: $notification-bg !important;
    border-left: 10px solid #4e83ff !important;
    color: #efd0ff !important;
}
