@import './vars';

@use 'sass:color';
@use 'sass:map';



$levels: 10;
$corners: 'top', 'right', 'bottom', 'left';

@mixin bg-blur-list {
    @for $i from 1 through 4 {
        .has-bg-blur-#{$i} {
            backdrop-filter: blur(#{$i * 5}px);
            -webkit-backdrop-filter: blur(#{$i * 5}px);
        }
    }
}

@include bg-blur-list;

@mixin generate-color-classes($color, $colorName) {
    .has-background-#{$colorName} {
        background: $color !important;
    }

    .is-#{$colorName} {
        background: $color !important;
        border-color: $color !important;
        color: black !important;
    }

    @for $i from 1 through $levels {
        .has-background-#{$colorName}-o-#{$i} {
            background-color: rgba($color, $i/10) !important;
        }
    }

    @for $i from 1 through 10 {
        @for $j from 1 through 5 {
            .has-border-#{$j}-#{$colorName}-o-#{$i} {
                border: #{$j}px solid rgba($color, $i/10) !important;
            }
        }
    }

    @each $corner in $corners {
        .has-#{$corner}-border-#{$colorName} {
            border-#{$corner}: 1px solid $color;
        }
    }

    .has-text-#{$colorName} {
        color: $color !important;
    }
}


@include generate-color-classes($redape, 'redape');
@include generate-color-classes($cyellow, 'cyellow');
@include generate-color-classes($cpurple, 'cpurple');
@include generate-color-classes($yellow, 'yellow');
@include generate-color-classes($hbrown, 'hbrown');
@include generate-color-classes($hgold, 'hgold');
@include generate-color-classes($hred, 'hred');
@include generate-color-classes($hgreen, 'hgreen');
@include generate-color-classes($hdarkgreen, 'hdarkgreen');
@include generate-color-classes($hlime, 'hlime');
@include generate-color-classes($primary, 'primary');
@include generate-color-classes($hdarkyellow, 'hdarkyellow');
@include generate-color-classes($hdarkgray, 'hdarkgray');
@include generate-color-classes($hdarkgray2, 'hdarkgray2');

.has-background-transparent {
    background: rgba(0, 0, 0, 0) !important;
}

.is-borderless {
    border: none !important;
}

.has-no-box-shadow {
    box-shadow: none !important;
}

.has-background-section-1{
    background-image : url("../media/backgrounds/section1-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

}

.has-background-section-2{
    background-image : url("../media/backgrounds/section2-background.jpg");
    background-color: rgba(0, 0, 0, 0.5);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;    
}

.has-background-section-3{
    background-image : url("../media/backgrounds/section3-background.jpg");
    background-color: rgba(0, 0, 0, 0.5);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.has-background-section-4{
    background-image : url("../media/backgrounds/section4-background.jpg");
    background-color: rgba(0, 0, 0, 0.5);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.has-background-section-5{
    background-image : url("../media/backgrounds/section5-background.jpg");
    background-color: rgba(0, 0, 0, 0.5);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.has-background-section-6{
    background-image : url("../media/backgrounds/section6-background.jpg");
    background-color: rgba(0, 0, 0, 0.5);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.has-background-section-7{
    background-image : url("../media/backgrounds/section7-background.jpg");
    background-color: rgba(0, 0, 0, 0.5);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.has-background-section-8{
    background-image : url("../media/backgrounds/section8-background.jpg");
    background-color: rgba(0, 0, 0, 0.5);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.has-background-section-9{
    background-image : url("../media/backgrounds/section9-background.jpg");
    background-color: rgba(0, 0, 0, 0.5);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.has-background-section-10{
    background-image : url("../media/backgrounds/section10-background.jpg");
    background-color: rgba(0, 0, 0, 0.5);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
