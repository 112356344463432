// .navbar{
//     position: fixed !important;
//     top: 0 !important;
//     width: 100% !important;

// }

#connection{
    @media screen and (min-width: 1023px){
        margin-right: 6rem !important;
    }
}


.navbar-start {
    .navbar-item {
        &:hover {
            background: #ffc200 !important;
            color: black !important;
        }

        &:focus {
            background: none;
        }
    }
}
.navbar-menu {
    @media (max-width: 1023px) {
        background: black !important;
    }
}

.navbar-grey {
    background: #3a3a3a !important;
}
