$redape: #9c0b0e;
$hred: #ff0000;
$hgreen: #00ff00;
$hdarkgreen: #00b050;
$hlime:#92d050;
$primary: #3b0b0e;
$cyellow: #ffc000;
$cpurple: #934bc9;
$hbrown: #8b6d19;
$hgold: #d0a325;
$yellow: #ffff00;
$hdarkyellow: #b8b72e;
$hdarkgray: #464849;
$hdarkgray2: #181818;
