@import url('https://fonts.googleapis.com/css2?family=Alegreya&family=BioRhyme:wght@300;400&family=Cormorant&family=Montserrat&family=Open+Sans&family=Playfair+Display&display=swap');

// .has-font-audiowide{
//     font-family: 'Audiowide', cursive;
// }

.has-font-montserrat {
    // font-family: 'Open Sans', sans-serif;
    font-family: 'Montserrat', sans-serif;
    // font-weight: bold;
}

.has-font-playfair {
    font-family: 'Playfair Display', serif;
}

.has-font-alegreya {
    font-family: 'Alegreya', serif;
}

.has-font-bioRhyme {
    font-family: 'BioRhyme', serif;
}

.has-font-title {
    font-weight: 700;
}

*:not(i) {
    @extend .has-font-montserrat;
}
